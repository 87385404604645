import logo from './assets/logo.svg';
import './App.css';
import {Picker} from './Picker';
import {Search} from "./Search";

export function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-uitleg">
          Hee mooierd! 💖<br />
          Als het goed is ben je hier omdat ik je heb gevraagd een kleur te kiezen 👩🏻‍🎨! Hieronder zie je een
          paar mogelijkheden 🎨. Kies een mooie kleur en laat me weten (in een reactie op de mail/app/💌
          waarin ik je vroeg dit te doen) welke kleur <em>jouw</em> 😎 kleur is!<br />
          Met de knop onderin kan je nieuwe kleuren kiezen, als er niks voor je bij zit 😢. Weet je het nog
          niet zeker? Klik dan een kleur aan om hem op je shortlist 📋 te zetten. Daar kan je maximaal 5
          kleuren op kwijt. Klik een kleur in de shortlist aan, om hem er weer vanaf 😵 te halen. De
          kleuren op je shortlist blijven bewaard als je de kleurenwisselknop indrukt (maar niet als je de
          pagina verlaat, daar was ik te lui voor).<br />
          <small>Sorry dat deze website zo lelijk is, ik beloof je dat ik het goed zal maken ☺️</small>
        </p>
      </header>
      <Search />
      <Picker />
    </div>
  );
}

