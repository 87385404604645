import './Search.css';
import {useCallback, useState} from "react";
import availableColors from './colors.json';
import {Color} from "./Color";
export function Search() {
    const [found, setFound] = useState();

    const onSearch = useCallback((event) => {
        const text = event.target.value;
        console.log(text);
        if (availableColors.includes(text)) {
            setFound(text)
        }
    }, []);
    return <div className="Search" >
        Zoeken: <input type="text" onChange={onSearch} />
        {!!found && <Color id={found} onClick={() => setFound()}/> }
    </div>
}
