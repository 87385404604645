import availableColors from './colors.json';
import {useCallback, useEffect, useState} from "react";
import {Color} from "./Color";
import './Picker.css';

export function Picker () {
    const [colors, setColors] = useState([]);
    const [selected, setSelected] = useState([]);

    const pickColors = useCallback(() => {
       const picked = [];
       while (picked.length < 8) {
           const color = availableColors[Math.floor(Math.random() * availableColors.length)];
           if (!picked.includes(color)) {
               picked.push(color);
           }
       }
       setColors(picked);
    }, []);

    useEffect(pickColors, [pickColors]);

    const addSelected = (color) => {
        setSelected((prev) => {
            if (!prev.includes(color)) {
                return [...prev, color].slice(-5)
            }
            return prev;
        });
    }

    const removeSelected = (color) => {
        setSelected((prev) => prev.filter(c => c !== color));
    }

    return <div className="Picker">
        {!!selected.length && <>
            <div className="Picker-selected">
                <h2>Shortlist!</h2>
                <div>
                {selected.map(color => <Color key={color} id={color} onClick={() => removeSelected(color)} />)}
                </div>
            </div>
        </>}
      <div className="Picker-colors">
        {colors.map(color => <Color key={color} id={color} onClick={() => addSelected(color)} />)}
      </div>
      <p className='Picker-more'>Niets moois gevonden 😢 <button onClick={pickColors}>Probeer andere kleuren 🤩</button></p>
    </div>
}
